"use client";

import Link from "next/link";
import { ChevronRight } from "lucide-react";
import posthog from "posthog-js";

export function SiteBanner() {
  return (
    <Link
      href="/affiliates"
      onClick={() => posthog.capture("affiliate_cta_clicked")}
      className="group relative top-0 bg-primary py-2 text-primary-foreground transition-all duration-300 md:py-4 block"
    >
      <div className="container justify-center h-full flex items-center text-sm">
        ✨
        <span className="ml-1 font-medium">
          <span className="md:hidden">Join our new Affiliate Program!</span>
          <span className="hidden md:inline">
            Join our Affiliate Program and earn up to $149 on each sale!
          </span>
        </span>
        <ChevronRight className="ml-1 h-4 w-4 transition-transform duration-300 ease-out group-hover:translate-x-1" />
      </div>
      <hr className="absolute bottom-0 m-0 h-px w-full bg-border" />
    </Link>
  );
}
